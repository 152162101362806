<template>
  <!-- 行业新闻 -->
  <div class="industry">
    <my-notice :id="1" />
  </div>
</template>

<script>
export default {
  name: "industry",
  data() {
    return {};
  },
};
</script>
